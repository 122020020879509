.container {
  margin-top: 200px;
  z-index: 0;
  position: relative;
}

.stone {
  margin-top: -250px;
  margin-bottom: 2em;
  width: 400px;
}
