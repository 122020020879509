.profile-banner {
  @apply bg-secondary-50;
  width: 100%;
}

@screen md {
  .profile-banner {
    margin-left: -32px;
    max-width: calc(100% + 64px);
    width: calc(100% + 64px);
  }
}

@screen lg {
  .profile-banner {
    margin-left: -64px;
    max-width: calc(100% + 128px);
    width: calc(100% + 128px);
  }
}
