@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --brand-dropbox: #0061FE;
    --brand-theinformation: #dd503d;
    --brand-loveirish: #009a4a;
    --brand-invisiblehatventures: #5d8725;
    --brand-duolingo: #50c902;
  }
}
